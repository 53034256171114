import { render, staticRenderFns } from "./settingV2.vue?vue&type=template&id=557e1b47&scoped=true&"
import script from "./settingV2.vue?vue&type=script&lang=js&"
export * from "./settingV2.vue?vue&type=script&lang=js&"
import style0 from "./settingV2.vue?vue&type=style&index=0&id=557e1b47&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "557e1b47",
  null
  
)

export default component.exports